import React from 'react';
import Calculator from './components/Calculator';
import Footer from './components/Footer';

const App: React.FC = () => {
  return (
    <>
      <Calculator />
      <Footer />
    </>
  );
};

export default App;
