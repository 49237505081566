import React from 'react';

const Footer: React.FC = () => {
  return (
    <div className='footer'>
      <a
        href='https://www.flaticon.com/free-icons/calculator'
        title='calculator icons'
      >
          Calculator icons created by Pixel perfect - Flaticon
      </a>
    </div>
  );
};

export default Footer;
