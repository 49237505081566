import ClearButton from './ClearButton';
import OperandButton from './OperandButton';
import SolveButton from './SolveButton';
import AddButton from './AddButton';
import SubtractButton from './SubtractButton';
import DivideButton from './DivideButton';
import MultiplyButton from './MultiplyButton';
import BackspaceButton from './BackspaceButton';
import DecimalButton from './DecimalButton';
import NegateButton from './NegateButton';

const Buttons = {
  ClearButton,
  OperandButton,
  SolveButton,
  AddButton,
  SubtractButton,
  DivideButton,
  MultiplyButton,
  BackspaceButton,
  DecimalButton,
  NegateButton,
};

export default Buttons;
